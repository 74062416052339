// src/actions/secretActions.js
import * as types from './actionTypes';
import { getAll, update, getStatus } from '../service/pihome';

export function loadPihome() {
  return function (dispatch) {
    return getAll()
      .then(pihome => {
        dispatch(loadPihomeSuccess(pihome));
      }).catch(error => {
        throw (error);
      });
  };
}

export function loadPihomeStatus() {
  return function (dispatch) {
    return getStatus()
      .then(status => {
        dispatch(loadStatusSuccess(status));
      }).catch(error => {
        throw (error);
      });
  };
}

export function updatePihome(pihome) {
  return function (dispatch) {
    return update(pihome).then(response => {
      dispatch(updatePihomeSuccess(response));
      if (response.endDate) {
        const curDate = new Date();
        const msDelay = response.endDate.getTime() - curDate.getTime();
        setTimeout(() => {
          delete response.endDate;
          response.state = !response.state;
          dispatch(updatePihomeSuccess(response));
        }, msDelay > 0 ? msDelay : 500);
        //console.log(response);
      }
    }).catch(error => {
      throw (error);
    });
  };
}

export function loadPihomeSuccess(pihome) {
  return { type: types.LOAD_PIHOME_SUCCESS, pihome };
}

export function updatePihomeSuccess(pihome) {
  return { type: types.UPDATE_PIHOME_SUCCESS, pihome };
}

export function loadStatusSuccess(status) {
  return { type: types.LOAD_STATUS_PIHOME, status };
}
