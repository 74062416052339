// src/reducers/streamReducer.js

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function streamReducer(state = initialState.stream, action) {
  switch(action.type) {
    case types.LOAD_STREAM_SUCCESS:
      return action.stream;
    default:
      return state;
  }
}
