// src/reducers/secretReducer.js

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function secretReducer(state = initialState.secrets, action) {
  switch (action.type) {
    case types.LOAD_SECRETS_SUCCESS:
      return action.secrets
    case types.DELETE_SECRETS_SUCCESS:
      return [
        ...state.filter(secret => secret.id !== action.id)
      ];
    case types.UPDATE_SECRETS_SUCCESS:
      return [
        ...state.filter(secret => secret.id !== action.secret.id),
        Object.assign({}, action.secret)
      ];
    case types.CREATE_SECRETS_SUCCESS:
      return [
        ...state.filter(secret => secret.id !== action.secret.id),
        Object.assign({}, action.secret)
      ];
    default:
      return state;
  }
}
