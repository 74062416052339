import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import BlogForm from './BlogForm';
import { connect } from 'react-redux';
import * as seriesActions from '../../actions/seriesActions';
import * as blogActions from '../../actions/blogActions';
import './Blog.css';

class EditBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      categories: [],
      blog: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.blog.id !== nextProps.blog.id) {
      this.setState({ blog: nextProps.blog });
    }
    if (this.props.blog.markdown !== nextProps.blog.markdown) {
      this.setState({ blog: nextProps.blog });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //console.log(name);

    let blog = Object.assign({}, this.state.blog);
    //console.log(event.target.value);
    blog[name] = value;
    this.setState({ blog });
  }

  onSubmit(event) {
    event.preventDefault();
    let blog = Object.assign({}, this.state.blog);
    blog.series_id = parseInt(blog.series_id);
    //console.log(blog);
    this.props.blogActions.updateBlog(blog);
  }

  render() {
    const { blog } = this.state;
    if (!blog.markdown) {
      const blogId = parseInt(this.props.match.params.id);
      if (!isNaN(blogId) && !this.props.blog.markdown) {
        this.props.blogActions.loadBlogDetails(blogId);
      }
      return (
        <div> Loading...</div>
      )
    } else {
      return (
        <div>
          <div className="App-header">
            <h2>Edit Blog</h2>
          </div>
          <p></p>
          <BlogForm
            onSubmit={this.onSubmit}
            handleChange={this.handleChange}
            categories={this.props.categories}
            series={this.props.series}
            blog={blog} />
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  let blog = undefined;
  const blogId = parseInt(ownProps.match.params.id);
  if (state.blogs.length > 0) {
    if (!isNaN(blogId)) {
      blog = Object.assign({}, state.blogs.find(item => item.id === blogId));
      //get the series id for this blog.
      const series = state.series.find(item => item.created.getTime() === blog.seriesCreated.getTime());
      if (series) {
        blog.series_id = series.id;
        delete blog.series;
        delete blog.seriesCreated;
        delete blog.linkedin;
        delete blog.firstname;
        delete blog.lastname;
        delete blog.images;
      }
    }
    if (blog && blog.id) {
      return {
        blog: blog,
        series: state.series,
        categories: state.blogCategories,
        loading: false
      };
    } else {
      return {
        blog: {
          title: "",
          description: "",
          markdown: "",
          series_id: undefined,
          category: undefined
        },
        series: state.series,
        categories: state.blogCategories,
        loading: false
      };
    }
  } else {
    return {
      blog: {
        title: "",
        description: "",
        markdown: "",
        series_id: undefined,
        category: undefined
      },
      series: state.series,
      categories: state.blogCategories,
      loading: true
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(seriesActions, dispatch),
    blogActions: bindActionCreators(blogActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBlog);