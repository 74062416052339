const URL = `/api/v1/pihome`;

function getAll() {
  return new Promise((resolve, reject) => {
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then(rtnData => {
        //setup date objects.
        const modData = rtnData.map((item) => {
          item.created = new Date(item.created);
          item.modified = new Date(item.modified);
          return item;
        })
        resolve(modData);
      })
      .catch((err) => reject(err));
  });
}

function getStatus() {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/state`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then(rtnData => {
        //setup date objects.
        const modData = rtnData.map((item) => {
          item.date = new Date(item.date);
          if (item.end_date)
            item.endDate = new Date(item.end_date);
          return item;
        })
        resolve(modData);
      })
      .catch((err) => reject(err));
  });
}

function update(control) {
  //
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${control.id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "PUT",
      body: JSON.stringify(control)
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then((result) => {
        //apply date items.
        result.date = new Date(result.date);
        if (result.end_date) {
          result.endDate = new Date(result.end_date);
        }
        resolve(result);
      })
      .catch((err) => reject(err));
  });
}

function reboot(name) {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/restart/${name}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then((result) => {
        //apply date items.
        resolve(result);
      })
      .catch((err) => reject(err));
  })
}

export { getAll, update, getStatus, reboot }
