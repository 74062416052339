import React from 'react';
import moment from 'moment';
import LineChartCard from '../antelope/LineChartCard';

const timeFormat = 'MM/DD/YYYY hh:mm A';
const getGraphData = (data) => {
  const leftovers = [];

  for (var i = 0; i < data.length; i++) {
    //need to use code to seperate datasets for chart
    if (!leftovers[data[i].code]) {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      const color = "rgba(" + r + ", " + g + ", " + b + ", 0.9)";
      leftovers[data[i].code] = {
        label: data[i].code,
        borderColor: color,
        backgroundColor: color,
        fill: false,
        data: []
      };
    }
    leftovers[data[i].code].data.push({
      x: moment(data[i].created).format(timeFormat),
      y: data[i].count
    });
  }

  const graphData = [];
  for (const item in leftovers) {
    graphData.push(leftovers[item]);
  }

  return graphData;
};
const getLabels = (data) => {
  return data.reduce((acc, item) => {
    const insertItem = moment(item.created).format(timeFormat);
    if (!acc.includes(insertItem)) {
      acc.push(insertItem);
    }
    return acc;
  }, []);
}
const getOptions = () => {
  return {
    responsive: true,
    hover: {
      mode: 'nearest',
      intersect: true
    },
    stacked: false,
    scales: {
      xAxes: [{
        type: 'time',
        display: true,
        scaleLabel: {
          display: true,
          labelString: "Date"
        },
        time: {
          format: timeFormat
        }
      }],
      yAxes: [{
        type: 'linear',
        display: true,
        scaleLabel: {
          display: true,
          labelString: "Value"
        },
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
};
const LeftoverChart = ({ data }) => (
  <LineChartCard
    title={"Licenses"}
    datasets={getGraphData(data)}
    labels={getLabels(data)}
    options={getOptions()}
    xLabel="Date"
    yLabel="Count"
    legend={false} />
);

export default LeftoverChart;
