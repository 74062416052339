// src/actions/secretActions.js
import * as types from './actionTypes';
import { create, remove, getAll, update } from '../service/secret';

export function loadSecrets() {
  return function (dispatch) {
    return getAll()
      .then(secrets => {
        dispatch(loadSecretsSuccess(secrets));
      }).catch(error => {
        throw (error);
      });
  };
}

export function removeSecret(id) {
  return function (dispatch) {
    return remove(id)
      .then(result => {
        dispatch(deleteSecretSuccess(id));
      }).catch(error => {
        throw (error);
      })
  }
}

export function createSecret(secret) {
  return function (dispatch) {
    return create(secret).then(response => {
      dispatch(createSecretSuccess(response));
      return response;
    }).catch(error => {
      throw (error);
    });
  };
}

export function updateSecret(secret) {
  return function (dispatch) {
    return update(secret).then(response => {
      dispatch(updateSecretSuccess(response));
    }).catch(error => {
      throw (error);
    });
  };
}

export function loadSecretsSuccess(secrets) {
  return { type: types.LOAD_SECRETS_SUCCESS, secrets };
}

export function updateSecretSuccess(secret) {
  return { type: types.UPDATE_SECRETS_SUCCESS, secret };
}

export function createSecretSuccess(secret) {
  return { type: types.CREATE_SECRETS_SUCCESS, secret };
}

export function deleteSecretSuccess(id) {
  return { type: types.DELETE_SECRETS_SUCCESS, id };
}
