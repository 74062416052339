import React from 'react';
import { Link } from "react-router-dom";

const StreamTable = ({ movies }) => (
  <div className="row">
    {movies.map((movie) => (
      <div className="col-sm-3" key={movie.id}>
        <div className="card">
          <Link to={`/stream/${movie.id}`}>
            <img src={movie.img} className="card-img-top" alt={movie.alt} />
          </Link>
          <div className="card-body">
            <h5 className="card-title">{movie.title}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{movie.created.toLocaleDateString()}</h6>
            <p className="card-text">{movie.description}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default StreamTable;
