import React, { Component } from 'react';
import { connect } from 'react-redux';
import TempChart from '../components/chart/TempChart';
import GarageChart from '../components/chart/GarageChart';
import StatDisplay from '../components/StatDisplay/StatDisplay';
import GuageDisplay from '../components/StatDisplay/GuageDisplay';
import Stock from '../components/stock/stock';
import { logout } from '../actions/authActions';
import PowerChart from '../components/chart/PowerChart';
import MetricChart from '../components/chart/MetricChart';
import Chart from '../components/chart/Chart';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const delay = 300000; //5 min
    const endDate = new Date().getTime();

    this.state = {
      isLoaded: false,
      isLoading: false,
      error: false,
      maxDistance: {
        garage: 0.0
      },
      maxTemp: {
        upstairs: 0.0,
        garage: 0.0,
        brewery: 0.0
      },
      maxBattery: 48.0,
      maxSolar: 0.0,
      errMsg: '',
      beginDate: endDate - delay,
      endDate
    };
  };

  componentDidMount() {
  }

  render() {
    const { beginDate, endDate } = this.state;
    const bigChartBeginDate = endDate - 86400000; //24 hours

    const distanceTextMapping = [
      { value: 0, text: "Open", color: "danger" },
      { value: 3, text: "Closed/Car", color: "success" },
      { value: 7, text: "Closed", color: "success" }
    ];
    const tempTextMapping = [
      { value: 0.0, color: "danger" },
      { value: 40.0, color: "warning" },
      { value: 60.0, color: "success" }
    ]
    return (
      <div>
        <h2>Dashboard</h2>
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <GuageDisplay title={'Battery'} metric="battery.voltage" beginDate={beginDate} endDate={endDate} min={48.0} max={55.7} unit="volts" progressStyle="bg-success" />
          </div>
          <div className="col-sm-6 col-md-3">
            <GuageDisplay title={'Solar'} metric="solar.watts" beginDate={beginDate} endDate={endDate} min={0.0} max={4800.0} unit="watts" sumSources={true} />
          </div>
          {/* <div className="col-sm-6 col-md-3">
            <StatDisplay title={'Solar Yield'} metric="solar.day-yield" source="SmartSolar" beginDate={beginDate} endDate={endDate} unit="kWh" decimals={1} />
          </div> */}
          <div className="col-sm-6 col-md-3">
            <StatDisplay title={'Garage'} metric="distance" source="garage" beginDate={beginDate} endDate={endDate} unit="" decimals={2} textMapping={distanceTextMapping} />
          </div>
          <div className="col-sm-6 col-md-3">
            <StatDisplay title={'Upstairs'} metric="temperature" source="upstairs" beginDate={beginDate} endDate={endDate} unit="°F" decimals={2} textMapping={tempTextMapping} />
          </div>
          <div className="col-sm-6 col-md-3">
            <StatDisplay title={'Garage'} metric="temperature" source="garage" beginDate={beginDate} endDate={endDate} unit="°F" decimals={2} textMapping={tempTextMapping} />
          </div>
          <div className="col-sm-6 col-md-3">
            <StatDisplay title={'Laundry'} metric="temperature" source="laundry" beginDate={beginDate} endDate={endDate} unit="°F" decimals={2} textMapping={tempTextMapping} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <PowerChart />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Chart
              title={"Battery"}
              xLabel={"Date"}
              yLabel={"Volts"}
              metric={"battery.voltage"}
              beginDate={endDate - 86400000}
              endDate={endDate} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Chart
              title={"Charge Watts"}
              xLabel={"Date"}
              yLabel={"Watts"}
              metric={"solar.watts"}
              beginDate={endDate - 86400000}
              endDate={endDate} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Chart
              title={"Charge Amps"}
              xLabel={"Date"}
              yLabel={"Amps"}
              metric={"solar.current"}
              beginDate={endDate - 86400000}
              endDate={endDate} />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-sm-12">
            <MetricChart title={"Solar Day Yield"} xLabel={"Date"} yLabel={"kWh"} beginDate={bigChartBeginDate} endDate={endDate} metrics={["solar.day-yield"]} />
          </div>
        </div> */}
        <div className="row">
          <div className="col-sm-12">
            <TempChart />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <GarageChart />
          </div>
        </div>
      </div>
    );
  };
};

export default Dashboard;
