import { combineReducers } from 'redux'
import {
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_CLEAR
} from '../actions/actions'
import secrets from './secretReducer';
import blogs from "./blogReducer";
import auth from "./authReducer";
import series from './seriesReducer';
import blogCategories from './blogCategoryReducer';
import pihome from './pihomeReducer';
import dow from './dowReducer';
import stream from './streamReducer'

function alerts(state = {
  text: '',
  style: ''
}, action) {
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        text: action.text,
        style: 'alert-success'
      };
    case ALERT_ERROR:
      return {
        text: action.text,
        style: 'alert-danger'
      };
    case ALERT_CLEAR:
      return {
        text: '',
        style: ''
      };
    default:
      return state
  }
}

const rootReducer = combineReducers({
  alerts,
  auth,
  secrets,
  blogs,
  series,
  blogCategories,
  pihome,
  dow,
  stream
})

export default rootReducer
