// src/reducers/pihomeReducer.js

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function pihomeReducer(state = initialState.pihome, action) {
  switch (action.type) {
    case types.LOAD_PIHOME_SUCCESS:
      return action.pihome;
    case types.UPDATE_PIHOME_SUCCESS:
      const focus = state.filter((item) => item.id === action.pihome.control_id);
      const newItem = Object.assign({}, focus[0]);
      //newItem.state = 
      newItem.stateDate = action.pihome.date;
      newItem.endDate = action.pihome.endDate;
      newItem.state = action.pihome.state;
      const newState = [
        ...state.filter(pihome => pihome.id !== action.pihome.control_id),
        newItem
      ];
      return newState;
    case types.LOAD_STATUS_PIHOME:
      return [
        ...state.map((item) => {
          const focus = action.status.filter((i2) => i2.control_id === item.id);
          if (focus.length > 0) {
            item.state = focus[0].state;
            item.stateDate = focus[0].date;
            item.endDate = focus[0].endDate;
          }
          return item;
        })
      ];
    default:
      return state;
  }
}
