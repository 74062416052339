import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SecretForm from './SecretForm';
import * as secretActions from '../../actions/secretActions';

class CreateSecret extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      secret: props.secret || {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let secret = Object.assign({}, this.state.secret);
    secret[name] = value;
    this.setState({ secret });
  }

  onSubmit(event) {
    event.preventDefault();
    let secret = this.state.secret;
    if(secret.notes != undefined && secret.notes.length == 0) {
      delete secret.notes;
    }
    if (secret.id) {
      //update
      this.props.actions.updateSecret(secret)
        .then((result) => {
          //success! navigate to secrets page
          this.props.history.push("/secret");
        })
        .catch((err) => {
          this.setState({
            error: err
          });
        });
    } else {
      this.props.actions.createSecret(secret)
        .then((result) => {
          //success! navigate to secrets page
          this.props.history.push("/secret");
        })
        .catch((err) => {
          this.setState({
            error: err
          });
        });
    }
  }

  render() {
    const { error, secret } = this.state;
    const { isLoginSuccess } = this.props;
    const btnText = (secret.id) ? "Update" : "Create";
    if (!isLoginSuccess) {
      return (
        <Redirect to="/login" />
      );
    } else {
      return (
        <div>
          <h2>{btnText} Secret</h2>
          <SecretForm
            onSubmit={this.onSubmit}
            handleChange={this.handleChange}
            secret={secret}
            submitBtnText={btnText} />
          {error && <div class="alert alert-danger" role="alert">
            {error}
          </div>}
        </div>
      );
    }
  }
}

CreateSecret.propTypes = {
  //actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  let secret = undefined;
  const secretId = parseInt(ownProps.match.params.id);
  if (state.secrets.length > 0) {
    if (!isNaN(secretId)) {
      secret = Object.assign({}, state.secrets.find(item => item.id === secretId));
      if(!secret.notes) {
        secret.notes = "";
      }
      delete secret.isLink;
      return {
        secret,
        isLoginSuccess: !!state.auth.isLoggedIn,
      }
    }
  }
  return {
    isLoginSuccess: !!state.auth.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(secretActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSecret);
