import React from 'react';

const GetName = (secret) => {
  if(secret.isLink) {
    return <a href={secret.href} target="_blank">{secret.name}</a>
  } else {
    return <span>{secret.name}</span>
  }
}

const SecretTable = ({secrets, copyPass, editPass, deletePass}) => (
    <table className="table table-hover table-sm">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Username</th>
          <th>Notes</th>
          <th>Created</th>
          <th>Modified</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {secrets.map((secret) => (
          <tr key={secret.id}>
            <td><button outline="true" className="btn btn-info btn-sm" onClick={() => copyPass(secret.password)}><clr-icon shape="copy"></clr-icon></button></td>
            <td>{GetName(secret)}</td>
            <td>{secret.username}</td>
            <td>{secret.notes}</td>
            <td>{secret.created.toLocaleString()}</td>
            <td>{secret.modified.toLocaleString()}</td>
            <td><button outline="true" className="btn btn-primary btn-sm" onClick={() => editPass(secret.id)}><clr-icon shape="pencil"></clr-icon></button></td>
            <td><button outline="true" className="btn btn-danger btn-sm" onClick={() => deletePass(secret.id)}><clr-icon shape="trash"></clr-icon></button></td>
          </tr>
        ))}
      </tbody>
    </table>
);

export default SecretTable;
