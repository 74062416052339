const URL = `/api/dow`;

function getLeftovers() {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/leftover?begin=2020-06-15T15:32:310Z&end=${new Date()}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then(rtnData => {
        //setup date objects.
        const modData = rtnData.map((item) => {
          item.created = new Date(item.created);
          item.modified = new Date(item.modified);
          return item;
        })
        resolve(modData);
      })
      .catch((err) => reject(err));
  });
}

export { getLeftovers }
