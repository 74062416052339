import React, { PureComponent } from 'react';
import Chart from 'chart.js';


class LineChart extends PureComponent {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate(nextProps) {
    if(nextProps.datasets !== this.props.datasets)
      this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { datasets, labels, options, legend } = this.props;

    if (this.state && this.state.myChart && typeof this.state.myChart !== "undefined") {
      this.state.myChart.destroy();
    }

    let legendOptions = {}
    if(legend) {
      legendOptions = {
        display: true,
        labels: {
          fontColor: '#666666'
        }
      } 
    } else {
      legendOptions = {
        display: false
      }
    }

    if(options)
      options.legend = legendOptions;

    const myChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: labels,
        datasets: datasets
      },
      options: options
    });

    this.setState({
      myChart: myChart
    });
  }

  render() {
    return (
      <div>
        <canvas ref={this.chartRef} />
      </div>
    );
  }
}

export default LineChart;
