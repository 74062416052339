import React, { Component } from 'react';
import StatDisplay from '../StatDisplay/StatDisplay';

class Stock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stocks: []
    };
  }

  componentDidMount() {
    //load stock prices
    /*let stock = [{
      name: 'vmw',
      url: "https://www.google.com/search?q=vmw+stock&oq=vmw+stock&aqs=chrome..69i57j0i433j0l3j69i60l3.3124j1j7&sourceid=chrome&ie=UTF-8"
    }];
    fetch(stock.url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          //return Promise.reject(new Error('error'));
          console.error("error");
        else
          return response.json()
      })
      .then(rtnData => {
        //check for success..
        if (rtnData) {
          console.log(rtnData);
        }
      });*/
    this.setState({
      stocks: [{
        name: 'vmw',
        price: 154.00
      }, {
        name: 'et',
        price: 8.00
      }, {
        name: 'sun',
        price: 32.15
      }, {
        name: 'sfl',
        price: 7.98
      }]
    });
  }

  render() {
    const { stocks } = this.state;
    return (
      <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3 row-cols-sm-3">
        {stocks.map((item) => (
          <div key={item.name} className="col mb-4">
            <StatDisplay title={item.name} value={item.price} />
          </div>
        ))}
      </div>
    );
  }
}

export default Stock;