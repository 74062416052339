import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as dowActions from '../../actions/dowActions';
import LeftoverChart from './LeftoverChart';
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

const mapAnimalCode = (item) => {
  let text = 'Unknown';
  if (item === 'A') {
    text = 'Antelope';
  } else if (item === 'B') {
    text = 'Bear';
  } else if (item === 'D') {
    text = 'Deer';
  } else if (item === 'E') {
    text = 'Elk';
  } else if (item === 'M') {
    text = 'Moose';
  } else if (item === 'T') {
    text = 'Turkey'
  }
  return text;
}

class Dow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lt: 1000,
      b: new Date('8/4/20'),
      e: new Date()
    };
    this.updateRange = this.updateRange.bind(this);
    this.updateBeginDate = this.updateBeginDate.bind(this);
    this.updateEndDate = this.updateEndDate.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
  }

  componentDidMount() {
    //load the data
    if (this.props.leftovers.length === 0) {
      this.props.actions.loadLeftovers();
    }

    const query = new URLSearchParams(this.props.location.search);
    let lt = query.get("lt") || "1000";
    lt = parseInt(lt);
    let b = query.get("b") || '8/4/20';
    b = new Date(b);
    let e = query.get("e") || new Date();
    e = new Date(e);
    this.setState({
      lt,
      b,
      e
    });
  }

  updateRange(event) {
    this.setState({ lt: event.target.value });
    //this.updateUrl(event.target.value, this.state.b, this.state.e);
  }

  updateBeginDate(date) {
    if (date) {
      this.setState({ b: date });
      this.updateUrl(this.state.lt, date, this.state.e);
    } else {
      this.setState({ b: new Date('8/4/20') });
      this.updateUrl(this.state.lt, new Date('8/4/20'), this.state.e);
    }
  }

  updateEndDate(date) {
    if (date) {
      this.setState({ e: date });
      this.updateUrl(this.state.lt, this.state.b, date);
    } else {
      this.setState({ e: new Date('8/4/20') });
      this.updateUrl(this.state.lt, this.state.b, new Date('8/4/20'));
    }
  }

  updateUrl(lt, b, e) {
    const query = new URLSearchParams(this.props.location.search);
    let a = query.get("a") || "A";
    let s = query.get("s") || "A";
    window.history.replaceState(null, "Leftovers", `/leftover?a=${a}&s=${s}&lt=${lt}&b=${b.toLocaleString()}&e=${e.toLocaleString()}`);
  }

  render() {
    const { animals, leftovers } = this.props;
    const { lt, b, e } = this.state;

    //load query string
    const query = new URLSearchParams(this.props.location.search);
    let a = query.get("a") || "a";
    let s = query.get("s") || "a";
    a = a.toUpperCase();
    s = s.toUpperCase();

    let chartData = leftovers;
    if (s === 'A') {
      chartData = leftovers.filter((item) => item.code.startsWith(a));
    } else {
      chartData = leftovers.filter((item) => item.code.startsWith(a + s));
    }

    if (lt > -1) {
      chartData = chartData.filter((item) => item.count < lt);
    }

    if (b) {
      chartData = chartData.filter((item) => item.created >= b);
    }

    if (e) {
      chartData = chartData.filter((item) => item.created <= e);
    }

    if (leftovers.length > 0) {
      return (
        <div className="row">
          <div className="col-md-12">
            <h2>Leftover Licenses Colorado 2020</h2>
            <nav className="nav nav-pills flex-column flex-sm-row">
              {animals.map((animal) => (
                <Link
                  key={animal.id}
                  className={"flex-sm-fill text-sm-center nav-link" + (a === animal.id ? " active" : "")}
                  to={`/leftover?a=${animal.id}&s=${s}&lt=${lt}&b=${b}&e=${e}`}>
                  {animal.text}
                </Link>
              ))}
            </nav>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    Filters
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="customRange2">Count less than: {lt}</label>
                      </div>
                      <div className="col-md-9">
                        <input
                          type="range"
                          className="custom-range"
                          min="0"
                          max="1000"
                          id="customRange2"
                          value={lt}
                          step="10"
                          onChange={this.updateRange} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        Start Date
                      </div>
                      <div className="col-md-9">
                        <DatePicker
                          selected={this.state.b}
                          onChange={this.updateBeginDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        End Date
                      </div>
                      <div className="col-md-9">
                        <DatePicker
                          selected={this.state.e}
                          onChange={this.updateEndDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        Sex
                      </div>
                      <div className="col-md-9">
                        <div className="btn-group" role="group" aria-label="Basic example">
                          <Link
                            role="button"
                            className={"btn btn-secondary" + (s === 'A' ? " active" : "")}
                            to={`/leftover?a=${a}&s=A&lt=${lt}&b=${b}&e=${e}`}>
                            All
                      </Link>
                          <Link
                            role="button"
                            className={"btn btn-secondary" + (s === 'M' ? " active" : "")}
                            to={`/leftover?a=${a}&s=M&lt=${lt}&b=${b}&e=${e}`}>
                            Male
                      </Link>
                          <Link
                            role="button"
                            className={"btn btn-secondary" + (s === 'F' ? " active" : "")}
                            to={`/leftover?a=${a}&s=F&lt=${lt}&b=${b}&e=${e}`}>
                            Female
                      </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <LeftoverChart
                  data={chartData} />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    }
  }
};

function mapStateToProps(state, ownProps) {
  return {
    leftovers: state.dow.leftovers,
    animals: state.dow.leftovers.reduce((acc, item) => {
      if (!acc.includes(item.code[0])) {
        acc.push(item.code[0]);
      }
      return acc;
    }, []).map((item) => {
      return {
        id: item,
        text: mapAnimalCode(item)
      };
    })
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dowActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dow);