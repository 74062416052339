import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function seriesReducer(state = initialState.series, action) {
  switch(action.type) {
    case types.LOAD_SERIES_SUCCESS:
      return action.series
    case types.DELETE_SERIES_SUCCESS:
      return [
        ...state.filter(series => series.id !== action.series.id)
      ];
    case types.UPDATE_SERIES_SUCCESS:
      return [
        ...state.filter(series => series.id !== action.series.id),
        Object.assign({}, action.series)
      ];
    case types.CREATE_SERIES_SUCCESS:
      return [
        ...state.filter(series => series.id !== action.series.id),
        Object.assign({}, action.series)
      ];
    default:
      return state;
  }
}
