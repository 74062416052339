import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import SecretTable from './SecretTable';
import { connect } from 'react-redux';
import * as secretActions from '../../actions/secretActions';

class ListSecrets extends Component {
  constructor(props) {
    super(props);

    this.copyPass = this.copyPass.bind(this);
    this.deletePass = this.deletePass.bind(this);
    this.editPass = this.editPass.bind(this);
  }

  componentDidMount() {
    if (this.props.secrets.length === 0) {
      this.props.actions.loadSecrets();
    }
  }

  copyPass(password) {
    //copy to clipboard.
    const el = document.createElement('textarea');
    el.value = password;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  editPass(id) {
    //edit the secret
    this.props.history.push(`/secret/edit/${id}`);
  }

  deletePass(id) {
    //delete it.
    this.props.actions.removeSecret(id);
  }

  render() {
    const orderedSecrets = this.props.secrets.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 0));
    return (
      <div>
        <h2>Secrets</h2>
        <SecretTable
          secrets={orderedSecrets}
          copyPass={this.copyPass}
          editPass={this.editPass}
          deletePass={this.deletePass} />
      </div>
    );
  }
}

ListSecrets.propTypes = {
  /*secrets: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired*/
};

function mapStateToProps(state, ownProps) {
  return {
    secrets: state.secrets
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(secretActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSecrets);
