import React from 'react';
import ReactMarkdown from 'react-markdown';

const BlogForm = ({ onSubmit, handleChange, blog, categories, series }) => (
  <div className="container">
    <form>
      <div className="form-group row">
        <label htmlFor="titleText" className="col-form-label col-12 col-md-2">Title</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="title" placeholder="How to..." id="idTitle" value={blog.title} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="descriptionText" className="col-form-label col-12 col-md-2">Description</label>
        <div className=" col-12 col-md-10">
          <textarea className="form-control" type="textarea" name="description" placeholder="This will ..." id="descriptionText" rows="4" cols="50" value={blog.description} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="markdownText" className="col-form-label col-12 col-md-2">Markdown</label>
        <div className=" col-12 col-md-10">
          <textarea className="form-control" type="textarea" name="markdown" placeholder="markdown of the blog" id="markdownText" rows="12" cols="50" value={blog.markdown} onChange={handleChange} />
        </div>
        <ReactMarkdown
          source={blog.markdown}
          escapeHtml={false} />
      </div>
      <div className="form-group row">
        <label htmlFor="categorySelect" className="col-form-label col-12 col-md-2">Category</label>
        <div className=" col-12 col-md-10">
          <select className="form-control" name="category" id="categorySelect" value={blog.category} onChange={handleChange} >
            {
              categories.map((category) => (
                <option key={category.text}
                  value={category.id}>{category.text}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="seriesSelect" className="col-form-label col-12 col-md-2">Series</label>
        <div className=" col-12 col-md-10">
          <select className="form-control" name="series_id" id="seriesSelect" value={blog.series_id} onChange={handleChange} >
            {
              series.map((s) => (
                <option key={s.id}
                  value={s.id}>{s.title}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className="form-group row">
        <button className="btn btn-primary" color="primary" onClick={(e) => onSubmit(e)}>Create</button>
      </div>
    </form>
  </div>
);

export default BlogForm;
