import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import StreamTable from './StreamTable';
import { connect } from 'react-redux';
import * as streamActions from '../../actions/streamActions';

class ListStream extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.props.movies || this.props.movies.length === 0) {
      this.props.actions.loadStream();
    }
  }

  render() {
    if (this.props.movies) {
      return (
        <div>
          <h2>Stream</h2>
          <StreamTable
            movies={this.props.movies}
          />
        </div>
      );
    } else {
      return (
        <div>
          <h2>Stream</h2>
          <p>Nothing available yet.</p>
        </div>
      );
    }
  }
}

ListStream.propTypes = {
  /*secrets: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired*/
};

function mapStateToProps(state, ownProps) {
  return {
    movies: state.stream.movies
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(streamActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListStream);
