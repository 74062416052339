import React from 'react';
import './Draw.css';

const data = [{
  name: 'Elk',
  cost: 62.72,
  points: 5,
  codes: [{
    code: 'E-E-004-W2-R',
    visible: false,
    units: [3,4],
    tags: 8,
    applied: {8:1, 7:4, 6:7, 5:5, 4:10, 3:9, 2:12, 1:10, 0:4},
    drawOut: {8:1, 7:4, 6:3},
    season: [{
      begin: "2023-11-18T06:00:00.000Z",
      end: "2023-11-22T06:00:00.000Z"
    }, {
      begin: "2024-01-13T06:00:00.000Z",
      end: "2024-01-17T06:00:00.000Z"
    }]
  }, {
    code: 'E-E-004-W4-R',
    visible: true,
    selected: true,
    units: [3,4],
    tags: 12,
    applied: {8:1, 6:3, 5:1, 4:2, 3:5, 2:2, 1:6, 0:3},
    drawOut: {8:1, 6:3, 5:1, 4:2, 3:5},
    season: [{
      begin: "2023-12-16T06:00:00.000Z",
      end: "2023-12-20T06:00:00.000Z"
    }, {
      begin: "2024-01-13T06:00:00.000Z",
      end: "2024-01-17T06:00:00.000Z"
    }]
  }, {
    code: 'E-F-004-W4-R',
    visible: false,
    units: [3,4],
    tags: 25,
    applied: {2:3, 1:4, 0:11},
    drawOut: {2:3, 1:4, 0:11},
    season: [{
      begin: "2023-12-16T06:00:00.000Z",
      end: "2023-12-20T06:00:00.000Z"
    }]
  }, {
    code: 'E-E-003-O1-R',
    visible: false,
    units: [3,4,5,301,441],
    tags: 1000,
    applied: {8:1, 6:1, 5:2, 4:5, 3:10, 2:56, 1:464, 0:576},
    drawOut: {8:1, 6:1, 5:2, 4:5, 3:9, 2:48, 1:432, 0:76},
    season: [{
      begin: "2023-10-14T06:00:00.000Z",
      end: "2023-10-18T06:00:00.000Z"
    }]
  }, {
    code: 'E-F-003-O1-R',
    visible: false,
    units: [3,4,5,214,301,441],
    tags: 500,
    applied: {7:1, 6:1, 4:1, 3:3, 2:8, 1:62, 0:288},
    drawOut: {7:1, 6:1, 4:1, 3:2, 2:7, 1:62, 0:232},
    season: [{
      begin: "2023-10-14T06:00:00.000Z",
      end: "2023-10-18T06:00:00.000Z"
    }]
  }]
}, {
  name: 'Deer',
  cost: 45.46,
  points: 0,
  codes: [{
    code: 'D-F-004-W2-R',
    visible: true,
    selected: true,
    units: [3,4],
    tags: 19,
    applied: {10:1, 6:2, 5:2, 3:1, 2:3, 1:3, 0:5},
    drawOut: {10:1, 6:2, 5:2, 3:1, 2:3, 1:3, 0:4},
    season: [{
      begin: "2023-12-16T06:00:00.000Z",
      end: "2023-12-20T06:00:00.000Z"
    }, {
      begin: "2024-01-06T06:00:00.000Z",
      end: "2024-01-10T06:00:00.000Z"
    }]
  }, {
    code: 'D-F-018-O2-R',
    visible: true,
    units: [18,28,37,371],
    tags: 925,
    applied: {7:1, 5:1, 4:1, 3:3, 2:10, 1:25, 0:315},
    drawOut: {7:1, 5:1, 4:1, 3:3, 2:10, 1:25, 0:315},
    season: [{
      begin: "2023-10-28T06:00:00.000Z",
      end: "2023-11-05T06:00:00.000Z"
    }]
  }, {
    code: 'D-F-018-O3-R',
    visible: false,
    units: [18,28,37,371],
    tags: 875,
    applied: {9:1, 6:2, 3:2, 2:2, 1:20, 0:181},
    drawOut: {9:1, 6:2, 3:2, 2:2, 1:20, 0:181},
    season: [{
      begin: "2023-11-11T06:00:00.000Z",
      end: "2023-11-17T06:00:00.000Z"
    }]
  }]/*, {
    code: 'D-F-012-O2-R',
    units: [12,13,23,24],
    tags: 250,
    applied: {7:1, 6:2, 5:1, 4:1, 3:2, 2:5, 1:13, 0:80},
    drawOut: {7:1, 6:2, 5:1, 4:1, 3:2, 2:5, 1:13, 0:80},
    season: [{
      begin: "2023-10-28T06:00:00.000Z",
      end: "2023-11-05T06:00:00.000Z"
    }]
  }, {
    code: 'D-F-012-O3-R',
    units: [12,13,23,24],
    tags: 250,
    applied: {5:1, 2:4, 1:10, 0:47},
    drawOut: {5:1, 2:4, 1:10, 0:47},
    season: [{
      begin: "2023-11-11T06:00:00.000Z",
      end: "2023-11-17T06:00:00.000Z"
    }]
  }, {
    code: 'D-F-055-O2-R',
    units: [55],
    tags: 175,
    applied: {5:1, 4:2, 3:2, 2:6, 1:29, 0:65},
    drawOut: {5:1, 4:2, 3:1, 2:6, 1:29, 0:64},
    season: [{
      begin: "2023-10-28T06:00:00.000Z",
      end: "2023-11-05T06:00:00.000Z"
    }]
  }, {
    code: 'D-F-055-O3-R',
    units: [55],
    tags: 90,
    applied: {4:1, 3:1, 2:12, 1:7, 0:35},
    drawOut: {4:1, 3:1, 2:12, 1:7, 0:31},
    season: [{
      begin: "2023-11-11T06:00:00.000Z",
      end: "2023-11-17T06:00:00.000Z"
    }]
  }]*/
}, {
  name: 'Pronghorn',
  cost: 45.46,
  points: 0,
  codes: [{
    code: 'A-F-136-O1-R',
    selected: true,
    visible: true,
    units: [136,143],
    tags: 40,
    applied: {4:1, 2:1, 1:2, 0:14},
    drawOut: {4:1, 2:1, 1:2, 0:14},
    season: [{
      begin: "2023-10-07T06:00:00.000Z",
      end: "2023-10-15T06:00:00.000Z"
    }]
  }, {
    code: 'A-F-137-O1-R',
    visible: false,
    units: [137,138,144],
    tags: 20,
    applied: {5:1, 3:1, 2:1, 1:13, 0:26},
    drawOut: {5:1, 3:1, 2:1, 1:8},
    season: [{
      begin: "2023-10-07T06:00:00.000Z",
      end: "2023-10-15T06:00:00.000Z"
    }]
  }, {
    code: 'A-F-087-O1-R',
    visible: false,
    units: [87],
    tags: 85,
    applied: {14:1, 12:1, 5:4, 4:8, 3:23, 2:83, 1:131, 0:156},
    drawOut: {14:1, 12:1, 5:4, 4:7, 3:18, 2:23},
    season: [{
      begin: "2023-10-07T06:00:00.000Z",
      end: "2023-10-15T06:00:00.000Z"
    }]
  }, {
    code: 'A-F-088-O1-R',
    visible: false,
    units: [88],
    tags: 50,
    applied: {8:1, 5:2, 4:1, 3:3, 2:28, 1:92, 0:61},
    drawOut: {8:1, 5:1, 4:1, 3:3, 2:22},
    season: [{
      begin: "2023-10-07T06:00:00.000Z",
      end: "2023-10-15T06:00:00.000Z"
    }]
  }]
}, {
  name: 'Moose',
  cost: 346.86,
  points: 17,
  codes: [{
    code: 'M-M-018-O1-X',
    visible: false,
    units: [18,181],
    tags: 14,
    applied: {21:6, 20:8, 19:11, 18:12, 17:22, 16:11, 15:11, 14:10, 13:16, 12:20, 11:6, 10:18, 9:19, 8:25, 7:34, 6:31, 5:35, 4:35},
    drawOut: {21:1, 18:1, 14:2, 12:1, 11:2, 8:1, 7:1, 5:2, 4:1},
    season: [{
      begin: "2023-09-09T06:00:00.000Z",
      end: "2023-09-30T06:00:00.000Z"
    }, {
      begin: "2023-09-09T06:00:00.000Z",
      end: "2023-09-17T06:00:00.000Z"
    }, {
      begin: "2023-10-01T06:00:00.000Z",
      end: "2023-10-14T06:00:00.000Z"
    }]
  }, {
    code: 'M-M-028-O1-X',
    visible: true,
    selected: true,
    units: [28],
    tags: 16,
    applied: {21:14, 20:9, 19:14, 18:21, 17:19, 16:22, 15:14, 14:15, 13:13, 12:25, 11:21, 10:22, 9:26, 8:25, 7:27, 6:37, 5:45, 4:34, 3:52, 2:43, 1:98},
    drawOut: {20:1, 19:1, 16:2, 15:1, 9:2, 8:1, 7:1, 6:2, 5:1, 1:1},
    season: [{
      begin: "2023-09-09T06:00:00.000Z",
      end: "2023-09-30T06:00:00.000Z"
    }, {
      begin: "2023-09-09T06:00:00.000Z",
      end: "2023-09-17T06:00:00.000Z"
    }, {
      begin: "2023-10-01T06:00:00.000Z",
      end: "2023-10-14T06:00:00.000Z"
    }]
  }]
}, {
  name: 'Bear',
  cost: 43.78,
  points: 12,
  codes: [{
    code: 'B-E-011-W1-R',
    visible: true,
    selected: true,
    units: [11,211],
    tags: 1,
    applied: {23:1, 20:3, 19:2, 18:3, 17:1, 16:4, 15:3, 14:3, 13:3, 12:2, 11:4, 10:4},
    drawOut: {23:1},
    season: [{
      begin: "2023-09-27T06:00:00.000Z",
      end: "2023-10-10T06:00:00.000Z"
    }]
  }]
}];

const Draw = () => {
  //update the dates to be real dates.
  for(let i = 0; i < data.length; i++) {
    for(let j = 0; j < data[i].codes.length; j++) {
      for(let k = 0; k < data[i].codes[j].season.length; k++) {
        data[i].codes[j].season[k].begin = new Date(data[i].codes[j].season[k].begin);
        data[i].codes[j].season[k].end = new Date(data[i].codes[j].season[k].end);
      }
      data[i].codes[j].pointsRequired = 100;
      data[i].codes[j].pointsRequiredPercent = 100.0;
      for(let key in data[i].codes[j].drawOut) {
        if(data[i].codes[j].pointsRequired > key) {
          data[i].codes[j].pointsRequired = key;
          data[i].codes[j].pointsRequiredPercent = data[i].codes[j].drawOut[key] / data[i].codes[j].applied[key];
        }
        //console.log(key, data[i].codes[j].drawOut[key]);
      }
    }
  }
  return (
    <div>
      <h3>2023 Colorado Draw</h3>
      {data.map((critter) => 
            <div key={critter.name} className="card card-spaced">
              <div className="card-header"><b>{critter.name}</b> - ${critter.cost} - Points: {critter.points}</div>
              <div className="card-body">
                {critter.codes.filter((item) => item.visible).map((item) => (
                  <div key={item.code} className="card">
                    <div className="card-body">
                      <div className="row" style={{marginTop: 0}}>
                        <div className="col">
                          <b>{item.code}</b>
                          <div>Units: {item.units.join(", ")}</div>
                          <div>Tags: {item.tags}</div>
                          <div>Dates:</div>
                          <ul>
                            {item.season.map((s) =>
                              <li key={`${item.code} - ${s.begin} - ${s.end}`}>{s.begin.toLocaleDateString()} - {s.end.toLocaleDateString()}</li>
                            )}
                          </ul>
                        </div>
                        <div className="col">
                          <div>Points required in 2022: <span className={(item.pointsRequired <= critter.points) ? "badge badge-success" : "badge badge-danger"}>{item.pointsRequired}</span></div>
                          <div>Draw rate at {item.pointsRequired} points is {item.drawOut[item.pointsRequired]}/{item.applied[item.pointsRequired]} = {(item.pointsRequiredPercent * 100.0).toFixed(1)}%</div>
                        </div>
                      </div>
                      {/* <hr /> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
    </div>
  );
};

export default Draw;