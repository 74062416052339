import React, { Component } from 'react';
import BarChart from '../chart/BarChart';

class Combo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datasets: {}
    }
  }

  updateState() {
    const { data } = this.props;

    //create the datasets.
    let datasets = [];
    for (let i = 0; i < data.length; i++) {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      let color = "rgba(" + r + ", " + g + ", " + b + ", 0.9)";
      datasets.push({
        type: data[i].type,
        label: data[i].label,
        data: data[i].data,
        fill: data[i].type === "bar" ? true : false,
        backgroundColor: color,
        borderColor: color
      });
    }

    this.setState({
      datasets: datasets
    });
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.data !== this.props.data)
      this.updateState();
  }

  render() {
    const { labels, title, xLabel, yLabel, legend } = this.props;
    const { datasets } = this.state;

    return (
      <div className="card">
        <div className="card-header">{title}</div>
        <div className="card-block">
          <div className="card-text">
            <BarChart
              labels={labels}
              datasets={datasets}
              title={title}
              xLabel={xLabel}
              yLabel={yLabel}
              legend={legend} />
          </div>
        </div>
      </div>
    );
  }
}

export default Combo;
