import * as types from './actionTypes';
import { callLoginApi, callLogoutApi, checkSessionApi } from '../service/auth';

export function login(username, password) {
  return dispatch => {
    dispatch(setLoginPending(true));
    dispatch(setLoginSuccess(false));
    dispatch(setLoginError(null));

    callLoginApi(username, password, (error, token) => {
      dispatch(setLoginPending(false));
      if (!error) {
        dispatch(setLoginSuccess(true));
        //localStorage.setItem("token", token);
      } else {
        dispatch(setLoginError(error));
      }
    });
  }
}

export function logout() {
  return dispatch => {
    callLogoutApi((err, result) => {
      if (err) {
        dispatch(setLoginError(err));
      } else {
        dispatch(setLoginSuccess(false));
        //localStorage.clear("token");
      }
    })
  }
}

export function isLoggedIn() {
  return async dispatch => {
    try {
      const result_1 = await checkSessionApi();
      dispatch(setLoginSuccess(true));
    } catch (error) {
      console.error(error);
      dispatch(setLoginSuccess(false));
    }
  }
}

export function setLoginPending(isLoginPending) {
  return {
    type: types.SET_LOGIN_PENDING,
    isLoginPending
  };
}

export function setLoginSuccess(val) {
  return {
    type: types.SET_LOGIN_SUCCESS,
    isLoggedIn: val
  };
}

export function setLoginError(loginError) {
  return {
    type: types.SET_LOGIN_ERROR,
    loginError
  };
}