const URL = `/api/blog`;
const URL_V1 = '/api/v1/blog';

function getAll() {
  return new Promise((resolve, reject) => {
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then(rtnData => {
        //setup date objects.
        const modData = rtnData.map((item) => {
          item.created = new Date(item.created);
          item.modified = new Date(item.modified);
          item.seriesCreated = new Date(item.seriesCreated);
          //remove null.
          if (!item.category) item.category = undefined;
          return item;
        })
        resolve(modData);
      })
      .catch((err) => reject(err));
  });
}

function getByID(id) {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${URL}/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "GET"
      })
        .then(response => {
          if (!response.ok)
            throw response.statusText;
          else
            return response.json()
        })
        .then(item => {
          //setup date objects.
          item.created = new Date(item.created);
          item.modified = new Date(item.modified);
          item.seriesCreated = new Date(item.seriesCreated);
          //remove null.
          if (!item.category) item.category = undefined;
          resolve(item);
        })
        .catch((err) => reject(err));
    }
    catch (err) {
      console.error(err)
      reject(err);
    };
  });
}

function create(blog) {
  return new Promise((resolve, reject) => {
    fetch(URL_V1, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(blog)
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json();
      })
      .then((result) => {
        //apply date items.
        result.created = new Date(result.created);
        result.modified = new Date(result.modified);
        //remove null.
        if (!result.category) result.category = undefined;
        resolve(result);
      }, (error) => {
        reject(error);
      });
  });
}

function update(blog) {
  return new Promise((resolve, reject) => {
    fetch(`${URL_V1}/${blog.id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(blog)
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json();
      })
      .then((result) => {
        //apply date items.
        result.created = new Date(result.created);
        result.modified = new Date(result.modified);
        //remove null.
        if (!result.category) result.category = undefined;
        resolve(result);
      }, (error) => {
        reject(error);
      });
  });
}

export { getAll, getByID, create, update }