import React from 'react';

const SecretForm = ({ secret, onSubmit, handleChange, submitBtnText }) => (
  <div className="container">
    <form>
      <div className="form-group row">
        <label htmlFor="idName" className="col-form-label col-12 col-md-2">Name</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="name" placeholder="Redbox" id="idName" value={secret.name} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="idUsername" className="col-form-label col-12 col-md-2">Username/Email</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="username" placeholder="" id="idUsername" value={secret.username} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="idPassword" className="col-form-label col-12 col-md-2">Password</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="password" name="password" placeholder="password" id="idPassword" value={secret.password} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="idHref" className="col-form-label col-12 col-md-2">Url/Href</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="href" placeholder="http://redbox.com/login" id="idHref" value={secret.href} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="notesText" className="col-form-label col-12 col-md-2">Notes</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="textarea" name="notes" placeholder="This will ..." id="notesText" rows="4" cols="50" value={secret.notes} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <button className="btn btn-primary" color="primary" onClick={(e) => onSubmit(e)}>{submitBtnText}</button>
      </div>
    </form>
  </div>
);

export default SecretForm;
