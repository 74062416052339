function getAll() {
  return new Promise((resolve, reject) => {
    const fakeData = {
      movies: [
        {
          id: "bRx1F9a",
          title: "April Fools!",
          description: "Christina decided to play an April fools trick on the kids.",
          created: new Date("04/01/21"),
          modified: new Date("04/02/21"),
          //href: "/movies/VID_20210401_192937017.mp4",
          //href: "https://1drv.ms/u/s!AvPZx6ks86CVm3hTiRwTcHFm7EP?e=p7wRC",
          //href: "https://public.ch.files.1drv.com/y4mkCcYjFrAHFQ_aD1Qq5iGnNK_0F1016PEEDPSpKFU4cs3ltLnPRL_5MMmvCc6TgMSG75IzmzDgwMnzQRGv8LFXjf28--ycFseTX-BaKtVsnKJ5b-Iti1tuL4qDL0FTbX09KYj-qdjnLjB0js_rgTWOqDNHmUd8A3BIzHbtRUOXtpkqnok3v3vbiLC-GDQFBHnTnioRoBNuqoMLhZxU2sg-cDwKGWrup9ucCwOTgOmE4U?access_token=EwAIA61DBAAUmcDj0azQ5tf1lkBfAvHLBzXl5ugAAXG3vOiew56pI8bCij6qJZWXA7837k%2b%2bnSXRUqPjrbfmu2z7k1aPhNiExjirSaron1TGFJYiNsRQY5vQ4c0ue1U/7ZtQgEw91oJjQe9hKB3YFJLIdb0L1xGnAhHLs3ppl9qti5s5pWGBVGAnlkreauxCeIY6KEKy0i3%2bRXDeUpw7KGnCF3I/aTAKY5SXg1056Tc3O4rmrq0k0rfJQ4JBDRobEOf7IvRqemIk8rchVE9sGPI%2bS4ygfSkQ%2bQhYsaUVyvIJ%2btgpgY2SnC6F16FCKDUsPS2NKeg0NLYajaogQ761HKG4i/PLMyHGB%2bLt8Zu/G4/G%2bR6ZsqNXJBPvq9wO1r4DZgAACNE4E9YUJwiM2AEXtAfvAu3C8MP1IRfyDmL0nMeR3DuXVhaYDH8fhS8zf9Hgcn2qiG9feOlGX%2bl%2ba1vcDKTZD7uI1Ljj9BgB18UX/fSXpIebkQxBVjs2hiXSV7ALCUbE/TWDayCQqPmO/XZY920e4Cf9jq226elNlWyTrisUcQpJjx7ftqGJDK/T2FZ9NGzhmZIpsei2JAfBvhvOheWm05G9GLX2RP2DybkEWpjNAVx1IXD10FTEJR4aZ3qQVPFtdd2UyTGM%2bGQvv6pp1mBlOEwSM8nm1TAmN%2bFnaKebUeg4Wmb7LFrcyDj1RGw9N5VxURSIsEBx1ShalrZ1N4oQALjBvxOr84AX6U39MTPxAm5fQl4P1D16OJRJ3LTFXrmLyjkVR7obFWYiUDtZPlQ6ktRlMPJQjp37TC5xx/hVtalBVjyROfMiCuhlQaKk2EWg1Zf5EZ/FT08wrqWMOYU0CjOrxxkt3mdig9U8PAq5FMP8szTj4z2WO5wRvQ5gM%2b9X7r/q1qly4mJsQoHUibAFpXq/HTNkpm//%2bumuRrccjlsbevF066xABdQUajgEBz92ngHRvzGhDcjEaNtiCKXf9UQj6Yy9NB3doougLm0OpZkf8q%2bXFaE0q9RgPAzuwuB4EjyoBQI%3d",
          //href: "https://x1cqcg.ch.files.1drv.com/y4mbtzstcwteD6ow0dc9QlyTDFKWOySpQ0n94dZ4KzlSxmSlHrkWgJh0wf6D3dxK5GJHhszDb-_WGlaZ8-aIayyjTEYgXGkdXHS5DGkDybjcOIScAkG6vAL-jYhSjuzbhOQAe6zoqUQ8gJLguz6iQXLIwWUAB1rZGOCY7r5zUhGmlezkSj6-xNClGDDCZI-FDM0TpbugeZmhAA8LCToXzjz0HfA6uD9-2mwr9AsRjW30oQ/VID_20210401_192937017.mp4?psid=1",
          //href: "https://x1cqcg.ch.files.1drv.com/y4mECvSfIGanBjOFQu8l4LZ7pQtMb57AM5_ShSmY8UUMWFK5yN4wxk_84qRbatFxSnWd-3m1RxDzwLGVuftKlNR5KSV_Lz005byB5QDyuAFFL8K9CqeuBW1xJ7gik_1T25grvTbKuxSCzzFplz71RAwbNGoYzKj4uG2Pkwv74Ygg74PXO-GzrzFarnahfC040cnkJT9uE1y3nhIFHVVvu_zdA/VID_20210401_192937017.mp4",
          href: "https://onedrive.live.com/embed?cid=95A0F32CA9C7D9F3&resid=95A0F32CA9C7D9F3%213576&authkey=AAXDI-yuMRHCRKk",
          img: "/images/april_fools.PNG",
          alt: "april fools trick"
        }
      ],
      music: []
    };
    //setup date objects.
    //const modData = fakeData.map((item) => {
    //  item.created = new Date(item.created);
    //  item.modified = new Date(item.modified);
    //  return item;
    //})
    //resolve(modData);
    resolve(fakeData);
  });
}

export { getAll }
