import * as types from './actionTypes';
import { getAll } from '../service/stream';

export function loadStream() {
  return function (dispatch) {
    return getAll()
      .then(stream => {
        dispatch(loadStreamSuccess(stream));
      }).catch(error => {
        throw (error);
      });
  };
}

export function loadStreamSuccess(stream) {
  return { type: types.LOAD_STREAM_SUCCESS, stream };
}
