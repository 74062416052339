import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/authActions';
import { Redirect } from 'react-router-dom';
//import login from '../../reducers/reducers';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state;
    this.props.dispatch(login(username, password))
  }

  render() {
    let { isLoginPending, isLoginSuccess, loginError } = this.props;
    if (isLoginSuccess) {
      return (
        <Redirect to="/dashboard" />
        // <Redirect to="/" />
      );
    } else if (isLoginPending)
      return (
        <div>
          <span className="spinner spinner-inline spinner-inverse">
            Loading...
          </span>
          <span>
            Loading...
          </span>
        </div>
      );
    else
      return (
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label htmlFor="username" className="col-sm-2 col-form-label">Username</label>
            <div className="col-sm-10">
              <input className="form-control" type="text" name="username" id="username" placeholder="username" value={this.state.username}
                onChange={this.handleInputChange} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
            <div className="col-sm-10">
              <input className="form-control" type="password" name="password" id="password" placeholder="password" value={this.state.password}
                onChange={this.handleInputChange} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-10">
              <button className="btn btn-primary" disabled={this.state.username.length < 1 || this.state.password.length < 1}>Submit</button>
            </div>
          </div>
          {loginError && <div className='alert alert-danger' role="alert">{loginError.message}</div>}
          {isLoginPending && <div>Please wait...</div>}
          {isLoginSuccess && <div>Success.</div>}
        </form>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoginPending: state.auth.isLoginPending,
    isLoginSuccess: !!state.auth.isLoggedIn,
    loginError: state.auth.loginError
  };
}

export default connect(mapStateToProps)(LoginForm);
