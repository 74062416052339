import React, { Component } from 'react';
import LineChart from './LineChart';
import { connect } from 'react-redux';

const timeFormat = 'MM/DD/YYYY h:mm A';

class PowerChart extends Component {
  constructor(props) {
    super(props);

    const endDate = new Date().getTime();

    this.state = {
      error: null,
      isLoaded: false,
      title: "Power",
      xLabel: "Date",
      yLabel: "Volts (v)",
      beginDate: endDate - 86400000,
      endDate: endDate,
      metric: "battery.voltage",
      steppedLine: props.steppedLine,
      timeSpan: 1,
    };
    this.loadChart = this.loadChart.bind(this);
  }

  componentDidMount() {
    this.loadChart(1);
  }

  loadChartData(timeSpan) {
    return new Promise((resolve, reject) => {
      const metrics = [
        'battery.voltage',
        'solar.watts'
      ];
      //const { beginDate, endDate } = this.state;
      const endDate = new Date().getTime();
      var beginDate = endDate - Math.floor(86400000 / timeSpan);
      if (timeSpan == 16) {
        beginDate = endDate - 86400000 * 2;
      } else if (timeSpan == 17) {
        beginDate = endDate - 86400000 * 3;
      } else if (timeSpan == 18) {
        beginDate = endDate - 86400000 * 7;
      } else if (timeSpan == 19) {
        beginDate = endDate - 86400000 * 9;
      }

      const dataPromises = metrics.map(
        (item) => fetch(
          `/api/v1/metrics?m=${encodeURIComponent(item)}&b=${beginDate}&e=${endDate}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "GET"
        }));
      Promise.all(dataPromises).then((responses) => {
        const jsonPromises = [];
        for (let i = 0; i < responses.length; i++) {
          if (!responses[i].ok) {
            reject(new Error('error'));
          }
          jsonPromises.push(responses[i].json());
        }
        Promise.all(jsonPromises).then((data) => {
          resolve(data);
        })
      });
    });
  }

  loadChart = async (timeSpan) => {
    const { metric, beginDate, endDate, xLabel, yLabel, title } = this.state;
    const chartData = await this.loadChartData(timeSpan);

    let data2 = {
      battery: [],
    };
    for (let i = 0; i < chartData.length; i++) {
      if (chartData[i]) {
        //convert to date objects.
        for (let j = 0; j < chartData[i].length; j++) {
          chartData[i][j].date = new Date(chartData[i][j].date);
          chartData[i][j].value = parseFloat(chartData[i][j].value.toFixed(2));

          let key = ""
          if (i == 0) {
            key = "battery"
          } else {
            key = chartData[i][j].source;
            if (!data2[key]) {
              data2[key] = [];
            }
          }
          data2[key].push({
            x: chartData[i][j].date,
            y: chartData[i][j].value
          });
        }
      }
    }
    //console.log(data2);

    const labels2 = []; //chartData[0].map(item => item.date);

    const yAxes= [{
      id: 'battery',
      scaleLabel: {
        display: true,
        labelString: yLabel
      }
    }];

    //create the datasets.
    let datasets2 = [];
    const colors = {
      battery: 'rgba(75, 160, 54, 0.5)',
      solar: 'rgba(95, 69, 201, 0.5)'
    }
    const colorChoices = [
      'rgba(95, 69, 201, 0.5)',
      'rgba(155, 39, 39, 0.5)',
      'rgba(90, 90, 91, 0.5)'
    ]
    let colorIndex = 0;
    for (let propName in data2) {
      if (!colors[propName]) {
        colors[propName] = colorChoices[colorIndex];
        colorIndex++;
      }

      if(propName != 'battery') {
        yAxes.push({
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: "Watts (W)"
          },
          id: propName,
          gridLines: {
            drawOnChartArea: false
          }
        })
      }
      //print out color so we can get a set of colors to use in charts.
      if (data2[propName].length > 0) {
        datasets2.push({
          label: propName,
          data: data2[propName],
          backgroundColor: colors[propName],
          borderColor: colors[propName],
          steppedLine: false,
          fill: false,
          yAxisID: propName
        });
      }
    }

    //console.log(datasets2);
    //console.log(colorIndex);

    this.setState({
      isLoaded: true,
      timeSpan,
      labels: labels2,
      datasets: datasets2,
      options: {
        //responsive: true,
        title: {
          text: title
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              parser: timeFormat,
              // round: 'day'
              tooltipFormat: 'll h:mm A'
            },
            scaleLabel: {
              display: true,
              labelString: xLabel
            }
          }],
          yAxes
        }
      }
    });
  }

  render() {
    const { title, isLoaded, labels, datasets, options, xLabel, yLabel, timeSpan } = this.state;

    if (!isLoaded) {
      return (
        <div>
          <span className="spinner spinner-inverse spinner-sm">
            Loading...
          </span>
        </div>
      );
    } else {
      return (
        <div className="card">
          <div className="card-header">{title} - <button outline="true" className="btn btn-success btn-sm" onClick={() => this.loadChart(timeSpan)}><clr-icon shape="refresh"></clr-icon></button>
            <div className="form-group row">
              <label className="col-form-label col-12 col-md-2">Timespan</label>
              <div className="col-12 col-md-10">
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                  <label className={timeSpan === 8 ? "btn btn-secondary active" : "btn btn-secondary"}>
                    <input type="radio" name="options" id="eights" onClick={() => { this.loadChart(8); }} /> 3 hr
                  </label>
                  <label className={timeSpan === 4 ? "btn btn-secondary active" : "btn btn-secondary"}>
                    <input type="radio" name="options" id="quarter" onClick={() => { this.loadChart(4); }} /> 6 hr
                  </label>
                  <label className={timeSpan === 2 ? "btn btn-secondary active" : "btn btn-secondary"}>
                    <input type="radio" name="options" id="half" onClick={() => { this.loadChart(2); }} /> 12 hr
                  </label>
                  <label className={timeSpan === 1 ? "btn btn-secondary active" : "btn btn-secondary"}>
                    <input type="radio" name="options" id="day" onClick={() => { this.loadChart(1); }} /> 24 hr
                  </label>
                  <label className={timeSpan === 16 ? "btn btn-secondary active" : "btn btn-secondary"}>
                    <input type="radio" name="options" id="day" onClick={() => { this.loadChart(16); }} /> 48 hr
                  </label>
                  <label className={timeSpan === 17 ? "btn btn-secondary active" : "btn btn-secondary"}>
                    <input type="radio" name="options" id="day" onClick={() => { this.loadChart(17); }} /> 72 hr
                  </label>
                  <label className={timeSpan === 18 ? "btn btn-secondary active" : "btn btn-secondary"}>
                    <input type="radio" name="options" id="day" onClick={() => { this.loadChart(18); }} /> 1 week
                  </label>
                  <label className={timeSpan === 19 ? "btn btn-secondary active" : "btn btn-secondary"}>
                    <input type="radio" name="options" id="day" onClick={() => { this.loadChart(19); }} /> 1+ week
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="card-block">
            <div className="card-text">
              <LineChart
                labels={labels}
                datasets={datasets}
                title={title}
                options={options}
                xLabel={xLabel}
                yLabel={yLabel}
                legend={true} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PowerChart;
