import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function blogReducer(state = initialState.blogs, action) {
  switch (action.type) {
    case types.LOAD_BLOGS_SUCCESS:
      return action.blogs
    case types.LOAD_BLOG_DETAILS_SUCCESS:
      return [
        ...state.filter(blog => blog.id !== action.blog.id),
        Object.assign({}, action.blog)
      ];
    case types.UPDATE_BLOG_SUCCESS:
      return [
        ...state.filter(blog => blog.id !== action.blog.id),
        Object.assign({}, action.blog)
      ];
    case types.CREATE_BLOG_SUCCESS:
      return [
        ...state.filter(blog => blog.id !== action.blog.id),
        Object.assign({}, action.blog)
      ];
    default:
      return state;
  }
}