import React, { Component } from 'react';
import LineChart from './LineChart';
import { connect } from 'react-redux';

const timeFormat = 'MM/DD/YYYY h:mm A';

class MetricChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
    };
    this.loadChart = this.loadChart.bind(this);
  }

  componentDidMount() {
    this.loadChart();
  }

  loadChartData() {
    return new Promise((resolve, reject) => {
      const { metrics, beginDate, endDate } = this.props;

      const dataPromises = metrics.map(
        (item) => fetch(
          `/api/v1/metrics?m=${encodeURIComponent(item)}&b=${beginDate}&e=${endDate}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "GET"
        }));
      Promise.all(dataPromises).then((responses) => {
        const jsonPromises = [];
        for (let i = 0; i < responses.length; i++) {
          if (!responses[i].ok) {
            reject(new Error('error'));
          }
          jsonPromises.push(responses[i].json());
        }
        Promise.all(jsonPromises).then((data) => {
          resolve(data);
        })
      });
    });
  }

  loadChart = async () => {
    const { metrics, xLabel, yLabel, yLabel2, title } = this.props;
    const chartData = await this.loadChartData();

    const rawData = { };
    for(let i = 0; i < metrics.length; i++) {
      rawData[metrics[i]] = [];
    }
    for (let i = 0; i < chartData.length; i++) {
      if (chartData[i]) {
        //convert to date objects.
        for (let j = 0; j < chartData[i].length; j++) {
          chartData[i][j].date = new Date(chartData[i][j].date);
          chartData[i][j].value = parseFloat(chartData[i][j].value.toFixed(2));

          rawData[metrics[i]].push({
            x: chartData[i][j].date,
            y: chartData[i][j].value
          });
        }
      }
    }

    //create the datasets.
    const datasets = [];
    for (let propName in rawData) {
      //print out color so we can get a set of colors to use in charts.
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      const color = "rgba(" + r + ", " + g + ", " + b + ", 0.5)";
      if (rawData[propName].length > 0) {
        const dataset = {
          label: propName,
          data: rawData[propName],
          backgroundColor: color,
          borderColor: color,
          steppedLine: false,
          fill: false
        };
        if(yLabel2 && yLabel2.length > 0) {
          dataset[yAxisID] = propName;
        }
        datasets.push(dataset);
      }
    }

    const yAxes = [];
    if(yLabel2 && yLabel2.length > 0) {
      yAxes.push({
        id: metrics[0],
        scaleLabel: {
          display: true,
          labelString: yLabel
        }
      });
      yAxes.push({
        id: metrics[1],
        scaleLabel: {
          display: true,
          labelString: yLabel2
        },
        position: 'right',
        gridLines: {
          drawOnChartArea: false
        }
      });
    } else {
      yAxes.push({
        scaleLabel: {
          display: true,
          labelString: yLabel
        }
      })
    }    

    this.setState({
      isLoaded: true,
      labels: [],
      datasets,
      options: {
        //responsive: true,
        title: {
          text: title
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              parser: timeFormat,
              // round: 'day'
              tooltipFormat: 'll h:mm A'
            },
            scaleLabel: {
              display: true,
              labelString: xLabel
            }
          }],
          yAxes
        }
      }
    });
  }

  render() {
    const { isLoaded, datasets, options } = this.state;
    const { title, labels, xLabel, yLabel } = this.props;

    if (!isLoaded) {
      return (
        <div>
          <span className="spinner spinner-inverse spinner-sm">
            Loading...
          </span>
        </div>
      );
    } else {
      return (
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-block">
            <div className="card-text">
              <LineChart
                labels={labels}
                datasets={datasets}
                title={title}
                options={options}
                xLabel={xLabel}
                yLabel={yLabel}
                legend={true} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MetricChart;
