import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import VideoCard from './VideoCard';
import { connect } from 'react-redux';
import * as streamActions from '../../actions/streamActions';

class StreamDetails extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.props.movies || this.props.movies.length === 0) {
      this.props.actions.loadStream();
    }
  }

  render() {
    const loading = this.props.loading;
    const error = this.props.error;
    const movie = this.props.movie;
    if (error) {
      return (
        <div>
          <h2>Error</h2>
          <p>Incorrect Reference</p>
        </div>
      );
    }
    else if (loading) {
      return (
        <div>
          <h2>Loading</h2>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-sm-12">
            <VideoCard
              movie={movie}
            />
          </div>
        </div>
      );
    }
  }
}

StreamDetails.propTypes = {
  /*secrets: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired*/
};

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id;
  if (state.stream.movies && state.stream.movies.length > 0) {
    if (id) {
      const movie = Object.assign({}, state.stream.movies.find(item => item.id === id));
      return {
        movie,
        loading: false,
        error: false
      };
    } else {
      return {
        loading: false,
        error: true
      };
    }
  } else {
    return {
      loading: true,
      error: false
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(streamActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamDetails);
