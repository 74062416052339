import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions';

class ListLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      logs: []
    };
  }

  componentDidMount() {
    let URL = `/api/v1/logs`;
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (response.status === 401) {
          //redirect to login
          this.props.dispatch(logout())
        } else {
          if (!response.ok) {
            this.setState({
              isLoaded: true,
              error: true
            });
            return Promise.reject(new Error('error'));
          } else
            return response.json()
        }
      })
      .then(rtnData => {
        this.setState({
          logs: rtnData.map((log) => {
            log.date = new Date(log.date);
            return log;
          }),
          isLoaded: true
        });
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          error: true
        });
      });
  }

  render() {
    const { isLoaded, logs } = this.state;
    const internal = logs.filter((log) => log.remote_addr && log.remote_addr.indexOf('192.168.0.') >= 0);
    const external = logs.filter((log) => !log.remote_addr || log.remote_addr.indexOf('192.168.0.') < 0);
    if (!isLoaded) {
      return (
        <div className="row justify-content-center">
          <div className="col">
            <span className="spinner spinner-inverse">
              Loading...
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h2>Logs</h2>
          <h3>External</h3>
          <table className="table table-inverse table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Method</th>
                <th scope="col">Url</th>
                <th scope="col">Status</th>
                <th scope="col">Remote Address</th>
                <th scope="col">Response</th>
              </tr>
            </thead>
            <tbody>
              {external.map((log) => (
                <tr key={log.date.getTime()}>
                  <td>{log.date.toLocaleString()}</td>
                  <td>{log.method}</td>
                  <td>{(log.url === "/login" || log.url === "/api/v1/secret") && <span class="badge badge-danger">{log.url}</span>}
                    {log.url !== "/login" && log.url !== "/api/v1/secret" && <span>{log.url}</span>}
                  </td>
                  <td>{log.status}</td>
                  <td>{log.remote_addr}</td>
                  <td>{log.response_time}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h3>Internal</h3>
          <table className="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Method</th>
                <th scope="col">Url</th>
                <th scope="col">Status</th>
                <th scope="col">Remote Address</th>
                <th scope="col">Response</th>
              </tr>
            </thead>
            <tbody>
              {internal.map((log) => (
                <tr key={log.date.getTime()}>
                  <td>{log.date.toLocaleString()}</td>
                  <td>{log.method}</td>
                  <td>{(log.url === "/login" || log.url === "/api/v1/secret") && <span className="badge badge-danger">{log.url}</span>}
                    {log.url !== "/login" && log.url !== "/api/v1/secret" && <span>{log.url}</span>}
                  </td>
                  <td>{log.status}</td>
                  <td>{log.remote_addr}</td>
                  <td>{log.response_time}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

export default ListLogs;
