import * as types from './actionTypes';
import { getAll, getByID, create, update } from '../service/blog';

export function loadBlogs() {
  return function (dispatch) {
    return getAll().then(blogs => {
      dispatch(loadBlogsSuccess(blogs));
    }).catch(error => {
      throw (error);
    });
  };
}

export function loadBlogDetails(id) {
  return function (dispatch) {
    return getByID(id).then(blog => {
      dispatch(loadBlogDetailsSuccess(blog));
    }).catch(error => {
      throw (error);
    });
  }
}

export function createBlog(blog) {
  return function (dispatch) {
    return create(blog).then(blog => {
      dispatch(createBlogSuccess(blog));
    }).catch(error => {
      throw (error);
    });
  }
}

export function updateBlog(blog) {
  return function (dispatch) {
    return update(blog).then(blog => {
      dispatch(updateBlogSuccess(blog));
    }).catch(error => {
      throw (error)
    });
  }
}

export function loadBlogsSuccess(blogs) {
  return { type: types.LOAD_BLOGS_SUCCESS, blogs };
}

export function loadBlogDetailsSuccess(blog) {
  return { type: types.LOAD_BLOG_DETAILS_SUCCESS, blog };
}

export function createBlogSuccess(blog) {
  return { type: types.CREATE_BLOG_SUCCESS, blog };
}

export function updateBlogSuccess(blog) {
  return { type: types.UPDATE_BLOG_SUCCESS, blog };
}