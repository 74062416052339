import React, { Component } from 'react';
import LineChart from '../chart/LineChart';

const timeFormat = 'MM/DD/YYYY h:mm A';

class Temp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      title: "Temperature",
      xLabel: "Date",
      yLabel: "Temp (F)",
      steppedLine: props.steppedLine
    };
  }

  componentDidMount() {
    this.loadChart();
  }

  loadChart() {
    let URL = `/data/metrics.json`;

    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          return Promise.reject(new Error('error'));
        else
          return response.json()
      })
      .then(rtnData => {
        //check for success..
        if (rtnData) {
          //convert date into date objects
          for (let i = 0; i < rtnData.length; i++) {
            rtnData[i].date = new Date(rtnData[i].date);
            rtnData[i].value = parseFloat(rtnData[i].value.toFixed(2));
          }

          let data = {};
          for (let i = 0; i < rtnData.length; i++) {
            //split the lines in the chart based on source
            if (!data[rtnData[i].source]) {
              data[rtnData[i].source] = [];
            }
            //format the x and y
            data[rtnData[i].source].push({
              x: rtnData[i].date,
              y: rtnData[i].value
            });
          }

          //get the labels and data in correct format
          const labels = rtnData.map(item => item.date)

          //default for steppedLine
          let steppedLine = false;
          if (this.props.steppedLine) {
            steppedLine = this.props.steppedLine
          }

          //create the datasets.
          let datasets = [];
          for (let propName in data) {
            let r = Math.floor(Math.random() * 255);
            let g = Math.floor(Math.random() * 255);
            let b = Math.floor(Math.random() * 255);
            let color = "rgba(" + r + ", " + g + ", " + b + ", 0.5)";
            //print out color so we can get a set of colors to use in charts.
            console.log({
              metric: this.props.metric,
              color,
              propName
            });
            if (data[propName].length > 0) {
              datasets.push({
                label: propName,
                data: data[propName],
                backgroundColor: color,
                borderColor: color,
                steppedLine: steppedLine,
                fill: false
              });
            }
          }

          this.setState({
            isLoaded: true,
            labels: labels,
            datasets: datasets,
            options: {
              //responsive: true,
              title: {
                text: this.state.title
              },
              scales: {
                xAxes: [{
                  type: 'time',
                  time: {
                    parser: timeFormat,
                    // round: 'day'
                    tooltipFormat: 'll h:mm A'
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.state.xLabel
                  }
                }],
                yAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: this.state.yLabel
                  }
                }]
              }
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            error: true
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          error: true
        });
      });
  }

  render() {
    const { isLoaded, labels, datasets, options, xLabel, yLabel, title } = this.state;

    if (!isLoaded) {
      return (
        <div>
          <span className="spinner spinner-inverse spinner-sm">
            Loading...
          </span>
        </div>
      );
    } else {
      return (
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-block">
            <div className="card-text">
              <LineChart
                labels={labels}
                datasets={datasets}
                title={title}
                options={options}
                xLabel={xLabel}
                yLabel={yLabel}
                legend={true} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Temp;
