import React from 'react';

const VideoCard = ({ movie }) => (
  <div className="card">
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    <iframe src={movie.href} width="180" height="320" frameBorder="0" scrolling="no" allowFullScreen sandbox="allow-top-navigation allow-scripts allow-same-origin"></iframe>
    <div className="card-body">
      <h5 className="card-title">{movie.title}</h5>
      <h6 className="card-subtitle mb-2 text-muted">{movie.created.toLocaleDateString()}</h6>
      <p className="card-text">{movie.description}</p>
    </div>
  </div>
);

export default VideoCard;
