import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import App from './components/App'
import './index.css';
import { loadBlogs } from "./actions/blogActions";
import { loadSeries } from './actions/seriesActions';
import { loadBlogCategories } from './actions/blogCategoryActions';
import { isLoggedIn } from './actions/authActions';

import configureStore from './store/index'

//import '@clr/ui/clr-ui-dark.min.css';
import '@clr/icons/clr-icons.min.css';
import '@clr/icons/clr-icons.min.js';
import '@webcomponents/custom-elements/custom-elements.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

const store = configureStore();

store.dispatch(isLoggedIn());
store.dispatch(loadBlogs());
store.dispatch(loadSeries());
store.dispatch(loadBlogCategories());

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
